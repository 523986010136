import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import { Link, navigate } from 'gatsby'
import firebase from '../firebase'
import Button from 'components/CustomButtons/Button.jsx'
import validateForms from '../components/validateForms'
import useFormValidation from '../components/useFormValidation'
import { socialAuth } from '../components/authFunctions'
import {
  FaFacebook,
  FaLinkedinIn,
  FaGoogle,
  FaEnvelope,
  FaLock,
} from 'react-icons/fa'
import { IconContext } from 'react-icons'

const INITIAL_STATE = {
  email: '',
  password: '',
}

const Lg = () => {
  const [authError, setAuthError] = React.useState('')
  const authenticateUser = async () => {
    const { email, password } = values
    if ((email, password)) {
      setAuthError(null)
      try {
        await firebase.login(email, password)
        navigate('/')
      } catch (err) {
        if (err.code === 'auth/user-not-found') {
          setAuthError('Your account is not registered with us')
        } else if (err.code === 'auth/wrong-password') {
          setAuthError('Invalid Password!')
        } else {
          setAuthError(err.message)
        }
      }
    } else {
      setAuthError('Please Enter Your Email and Password')
    }
  }
  const { handleChanges, values, handleBlur, errors } = useFormValidation(
    INITIAL_STATE,
    validateForms
  )

  const handleSubmit = e => {
    e.preventDefault()
    authenticateUser()
  }
  return (
    <Layout>
      <Wrapper>
        <SocialBox>
          <h4
            style={{
              fontWeight: 'bold',
              marginBottom: 30,
              color: '#fff',
              fontSize: 20,
            }}
          >
            Login
          </h4>
          <div style={{ flexDirection: 'row' }}>
            <SocialButton
              onClick={() => socialAuth(firebase, 'Facebook', setAuthError)}
            >
              <IconContext.Provider value={{ color: 'white', size: '40px' }}>
                <FaFacebook />
              </IconContext.Provider>
            </SocialButton>
            <SocialButton
              onClick={() => socialAuth(firebase, 'Google', setAuthError)}
            >
              <IconContext.Provider value={{ color: 'white', size: '40px' }}>
                <FaGoogle />
              </IconContext.Provider>
            </SocialButton>
            <SocialButton onClick={() => navigate('/popup')}>
              <IconContext.Provider value={{ color: 'white', size: '40px' }}>
                <FaLinkedinIn />
              </IconContext.Provider>
            </SocialButton>
          </div>
        </SocialBox>
        <FormBox>
          <CenterRow>
            <IconContext.Provider value={{ color: 'grey', size: '20px' }}>
              <FaEnvelope />
            </IconContext.Provider>
            <AuthInput
              type="email"
              name="email"
              placeholder="Email..."
              onChange={e => handleChanges(e)}
              value={values.email}
              onBlur={handleBlur}
            />
          </CenterRow>
          {errors.email && <ErrorText>{errors.email}</ErrorText>}
          <CenterRow>
            <IconContext.Provider value={{ color: 'grey', size: '20px' }}>
              <FaLock />
            </IconContext.Provider>
            <AuthInput
              type="password"
              name="password"
              placeholder="Password..."
              onChange={e => handleChanges(e)}
              value={values.password}
            />
          </CenterRow>
          {errors.password && <ErrorText>{errors.password}</ErrorText>}
          {authError && <ErrorText>{authError}</ErrorText>}
          <Button
            simple
            color="primary"
            size="lg"
            type="submit"
            onClick={e => handleSubmit(e)}
          >
            Get started
          </Button>
          <Button
            simple
            color="primary"
            size="lg"
            onClick={() => navigate('/forgotPassword')}
          >
            Forgot password
          </Button>
          <Button
            simple
            color="primary"
            size="lg"
            onClick={() => navigate('/privacy')}
          >
            Privacy Policy
          </Button>
          <Button simple color="primary" size="lg">
            <Link style={{ color: '#ff0066' }} to="/signup">
              Not a member? sign up
            </Link>
          </Button>
        </FormBox>
      </Wrapper>
    </Layout>
  )
}

export default Lg

const Wrapper = styled.div`
  background-image: url('../../static/uploads/flexBackground.jpg');
  background-size: cover;
  background-position: top center;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SocialBox = styled.div`
  background-color: #ff0066;
  display: flex;
  height: 200px;
  width: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;

  margin-bottom: -100px;
  z-index: 10;
`
const SocialButton = styled.button`
  background-color: transparent;
  border: none;
  margin: 0 10px;
`

const FormBox = styled.div`
  width: 400px;
  padding: 150px 50px 50px 50px;
  background-color: #fff;
  display: flex;
  border-radius: 16px;
  flex-direction: column;
`

const AuthInput = styled.input`
  width: 100%;
  background-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-width: 1px;
  border-bottom-color: ${props =>
    props.value.length > 0 ? '#f09000' : '#777'};
  color: #777;
  font-size: 18px;
  padding: 10px 0;
  margin-left: 10px;
  ::placeholder {
    font-size: 18px;
  }
`

const CenterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`

const ErrorText = styled.p`
  text-align: center;
  font-weight: bold;
  color: #f90000;
`
